import React from 'react'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Label from 'components/Label'

import { responsive } from 'components/ThemeProvider/theme';

const status = [
  {
    title: '無傷亡',
    id: 0,
  },
  {
    title: '體傷',
    id: 1,
  },
  {
    title: '死亡',
    id: 2,
  },
]

const carSts = [
  {
    title: '有',
    id: 'Y',
  },
  {
    title: '無',
    id: 'N',
  },
]

const RadioGroup = ({ label, name, readOnly, options, labelWidth }) => (
  <Flex mt="1em">
    <Label labelWidth={labelWidth}>{label}</Label>
    <Input disabled={readOnly} flex={1} type="radio" options={options} name={name} />
  </Flex>
)

const fields = {
  car: [
    [
      { name: 'driverName', label: '%t姓名' },
      { name: 'driverSts', Comp: RadioGroup, label: '%t狀況', options: status, labelWidth: '5em' },
      { name: 'passengerName', label: '乘客姓名' },
      { name: 'passengerSts', Comp: RadioGroup, label: '乘客狀況', options: status, labelWidth: '4em' },
    ],
    [
      { name: 'carOwner', label: '車主名稱' },
      { name: 'carNo', label: '車號' },
      { name: 'carType', label: '車種' },
      { name: 'carSts', label: '車損', Comp: RadioGroup, options: carSts, labelWidth: '2em' },
    ],
  ],
  ped: [
    [
      { name: 'pedestrianName', label: '%t姓名' },
      { name: 'pedestrianSts', Comp: RadioGroup, label: '%t狀況', options: status, labelWidth: '5em' },
    ],
    [],
  ],
}

const TrafficTypeForm = ({ title, id, mode, readOnly, type, ...props }) => {
  const activeFields = fields[type]
  const render = ({ name, label, Comp = Input.FastField, ...props }) => (
    <Comp
      key={name}
      readOnly={readOnly}
      name={`traffic.${mode}.${name}`}
      label={String(label).replace('%t', title)}
      {...props}
    />
  )
  return (
    <Box {...props}>
      <Box mt={responsive('1.5em', '2.25em')} fontSize="1.25em" color="darkPurple">{title}</Box>
      <Flex flexDirection={responsive('column', null, 'row')} mt={responsive('1em', '1.625em')}>
        <Box width={responsive(1, null, 1 / 2)} pr={responsive(0, null, '1.875em')} borderRight={responsive('none', null, '1px solid')} borderColor={responsive('none', 'purple')}>
          {activeFields[0] && activeFields[0].map(render)}
        </Box>
        <Box width={responsive(1, null, 1 / 2)} pl={responsive(0, null, '1.875em')} borderLeft={responsive('none', null, '1px solid')} borderColor={responsive('none', 'purple')}>
          {activeFields[1] && activeFields[1].map(render)}
        </Box>
      </Flex>
    </Box>
  )
}

export default TrafficTypeForm
