import React, { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

import Board from 'components/Board'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Label from 'components/Label'
import Dropdown from 'components/Dropdown'
import { responsive } from 'components/ThemeProvider/theme'

import TrafficTypeForm from './TrafficTypeForm';

const typeDate = [
  ['car', 'car'],
  ['ped', 'car'],
  ['car', 'ped'],
]

const applyData = [
  {
    title: '聲請人',
    mode: 'apply',
  },
  {
    title: '對造人',
    mode: 'versus',
  }
]

const TraficDataForm = ({ readOnly, id }) => {
  const { values, setFieldValue } = useFormikContext()
  const prevCarAccidentType = useRef(values.carAccidentType)
  useEffect(() => {
    if (prevCarAccidentType.current !== values.carAccidentType) {
      setFieldValue('traffic', {})
      prevCarAccidentType.current = values.carAccidentType
    }
  }, [values.carAccidentType, setFieldValue])
  return (
    <Box mb="1.5em">
      <Text mb="1.5em" fontSize="1.5em">車禍類型</Text>
      <Board px={responsive('1.5em', '2em')} pt="2em">
        <Flex alignItems={responsive('auto', 'flex-end')} flexDirection={responsive('column', 'row')}>
          <Label>車禍類型選擇</Label>
          <Dropdown.FastField
            disabled={readOnly}
            mt={responsive('1em', 0)}
            name="carAccidentType"
            width={responsive(1, 1 / 2)}
            options={[
              { label: '雙方當事人皆為車輛駕駛（騎乘）人', value: 1 },
              { label: '聲請人為行人，對造人為車輛駕駛（騎乘）人', value: 2 },
              { label: '聲請人為車輛駕駛（騎乘）人，對造人為行人', value: 3 },
            ]}
          />
        <Text mt={responsive('1em', 0)}>（請先行選擇車禍類型再填寫下面內容）</Text>
        </Flex>
          {values.carAccidentType > 0 && applyData.map(({ title, mode }, i) => (
            <TrafficTypeForm
              key={i}
              readOnly={readOnly}
              title={title}
              mode={mode}
              id={id}
              type={typeDate[values.carAccidentType - 1][i]}
            />
          ))}
      </Board>
    </Box>
  )
}

export default TraficDataForm
