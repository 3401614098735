import React, { createElement, useEffect, useMemo, useRef } from 'react'
import { useImmer } from 'use-immer'
import { FastField, useFormikContext, useField } from 'formik'
import { MdClose, MdAdd } from 'react-icons/md'
import range from 'lodash/range'

import Box from 'components/Box'
import Button from 'components/Button'
import Board from 'components/Board'
// import Text from 'components/Text'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Label from 'components/Label'
import { responsive } from 'components/ThemeProvider/theme'

import CodeDropdown from '../CodeDropdown'
import DateDropdown from './DateDropdown'
import AdressForm from './AdressForm'

const RenderForm = ({
  namespace,
  n,
  readOnly,
  index,
  i,
  canIncrease,
  increase,
  remove,
  mode,
}) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField({ name: namespace })
  const prevValue = useRef()
  const isVersus = namespace.startsWith('versus')

  useEffect(() => {
    if (field.value) {
      if (
        !prevValue.current ||
        field.value.national_number !== prevValue.current.national_number
      ) {
        // handle sex
        if (field.value.national_number && field.value.national_number[1]) {
          let sex
          if (field.value.manidentity == '2') {
            if (
              field.value.national_number[1] == 'A' ||
              field.value.national_number[1] == 'a'
            )
              sex = 'm'
            if (
              field.value.national_number[1] == 'C' ||
              field.value.national_number[1] == 'c'
            )
              sex = 'm'
            if (
              field.value.national_number[1] == 'B' ||
              field.value.national_number[1] == 'b'
            )
              sex = 'f'
            if (
              field.value.national_number[1] == 'D' ||
              field.value.national_number[1] == 'd'
            )
              sex = 'f'
          } else {
            if (field.value.national_number[1] == '1') sex = 'm'
            if (field.value.national_number[1] == '2') sex = 'f'
          }
          if (sex) setFieldValue(`${namespace}.sex`, sex)
        }
      }

      if (
        !prevValue.current ||
        field.value.manidentity !== prevValue.current.manidentity
      ) {
        // handle comtype
        setFieldValue(
          `${namespace}.comtype`,
          field.value.manidentity == '3' ? 'y' : 'n'
        )
        if (field.value.manidentity == '3') {
          setFieldValue(`${namespace}.sex`, '')
          setFieldValue(`${namespace}.national_number`, '')
        } else {
          setFieldValue(`${namespace}.company_number`, '')
        }
      }

      prevValue.current = field.value
    }
  }, [field.value])

  return useMemo(
    () => (
      <Board mb="1.5em" px={responsive('1em', '2em')}>
        <Flex
          flexDirection={responsive('column', 'column', 'row')}
          justifyContent={responsive('center', 'space-between')}
        >
          <Box
            pr={responsive(0, 0, '1.875em')}
            width={responsive(1, 1, 1 / 2)}
            borderRight={responsive('none', 'none', '1px solid')}
            borderColor={responsive('none', 'none', 'purple')}
          >
            <Input.FastField
              label={`${n}（代表人）`}
              name={`${namespace}.name`}
              readOnly={readOnly}
              note={`${n}如有數人的話，請填寫第一${n}之姓名`}
              isRequired
            />
            <Flex.Responsive
              alignItems={responsive('auto', 'flex-end')}
              as="label"
              mt="2.25em"
            >
              <Label isRequired>稱謂</Label>
              <CodeDropdown.FastField
                disabled={readOnly}
                path={`titles/${String.fromCharCode(65 + index)}`}
                flex={1}
                name={`${namespace}.titid`}
              />
            </Flex.Responsive>
            <Flex.Responsive
              alignItems={responsive('auto', 'flex-end')}
              mt={responsive('1em', '2.25em')}
            >
              <Input.FastField
                readOnly={readOnly}
                type="tel"
                label="連絡電話"
                name={`${namespace}.phone1`}
                isRequired={!isVersus}
              />
              <Input.FastField
                ml={responsive(0, '1em')}
                readOnly={readOnly}
                type="tel"
                label="手機"
                name={`${namespace}.phone2`}
                isRequired={!isVersus}
              />
            </Flex.Responsive>
            <AdressForm
              readOnly={readOnly}
              label="通訊地址"
              namespace={namespace}
              flexDirection="column"
              name="address"
              note={
                mode === 'versus' &&
                '對造人地址如有誤繕或錯誤，將導致聲請程序未完備。'
              }
              isRequired
            />
          </Box>
          <Box
            mt={responsive('1em', '1em', 0)}
            width={responsive(1, 1, 1 / 2)}
            pl={responsive(0, 0, '1.875em')}
            borderLeft={responsive('none', 'none', '1px solid')}
            borderColor={responsive('none', 'none', 'purple')}
          >
            <Flex.Responsive alignItems={responsive('auto', 'flex-end')}>
              <Input.Field
                label="身分"
                mt="1em"
                type="radio"
                readOnly={readOnly}
                cancelable
                name={`${namespace}.manidentity`}
                options={[
                  { title: '本國自然人', id: '1' },
                  { title: '外國自然人', id: '2' },
                  { title: '本國法人', id: '3' },
                ]}
                isRequired
              />
            </Flex.Responsive>
            <FastField name={`${namespace}.manidentity`}>
              {({ field }) => {
                if (!field.value) return null
                return field.value == '3' ? (
                  <Box mt={responsive('1em', '2.25em')}>
                    <Input.FastField
                      readOnly={readOnly}
                      label="統編"
                      name={`${namespace}.company_number`}
                      labelFontSize="1.125em"
                      isRequired={!isVersus}
                    />
                  </Box>
                ) : (
                  <Flex.Responsive
                    alignItems={responsive('auto', 'center')}
                    mt={responsive('1em', '2.25em')}
                  >
                    <Input.Field
                      label={field.value == '2' ? '居留證號' : '身分證字號'}
                      readOnly={readOnly}
                      name={`${namespace}.national_number`}
                      isRequired={!isVersus}
                    />
                    <Input.Field
                      width={responsive('100%', '18em')}
                      mt="1em"
                      ml={responsive(0, '1em')}
                      label="性別"
                      labelWidth="2.75em"
                      readOnly
                      type="radio"
                      name={`${namespace}.sex`}
                      options={[
                        { title: '男', id: 'm' },
                        { title: '女', id: 'f' },
                      ]}
                      isRequired={!isVersus}
                    />
                  </Flex.Responsive>
                )
              }}
            </FastField>
            {!isVersus && (
              <>
                <DateDropdown
                  readOnly={readOnly}
                  name="birth"
                  label="出生年月日"
                  namespace={namespace}
                  isRequired
                />
                <Input.FastField
                  readOnly={readOnly}
                  label="E-mail"
                  type="email"
                  mt="2.25em"
                  name={`${namespace}.email`}
                  note="Email如有誤繕或錯誤，將無法收到回覆訊息。"
                  isRequired
                />
              </>
            )}
          </Box>
        </Flex>
        {!readOnly && (
          <Flex.Responsive mx="-1em" mt="1.25em">
            {canIncrease && (
              <Button.Purple
                px={responsive('1rem', '5rem')}
                mx="1em"
                mt="1em"
                leftIcon={MdAdd}
                onClick={() => increase(n)}
              >
                新增共同{n}
              </Button.Purple>
            )}
            {i > 0 && (
              <Button.Danger
                px={responsive('1rem', '5rem')}
                mx="1em"
                mt="1em"
                leftIcon={MdClose}
                onClick={() => remove(i)}
              >
                移除共同{n} {i}
              </Button.Danger>
            )}
          </Flex.Responsive>
        )}
      </Board>
    ),
    [n, namespace, index, readOnly]
  )
}

const ClaimForm = ({ index, mode, readOnly, name }) => {
  const { values, setFieldValue } = useFormikContext()
  const [useData, updateData] = useImmer(range(values[mode].length).fill(name))
  const increase = (n) => {
    updateData((draft) => {
      const l = draft.push(n)
      setFieldValue(`${mode}.${l - 1}`, {})
    })
  }

  const remove = (i) => {
    updateData((draft) => {
      draft.splice(i, 1)
    })
    const clone = [...values[mode]]
    clone.splice(i, 1)
    setFieldValue(mode, [...clone])
  }

  return useMemo(
    () =>
      useData.map((n, i) => {
        const namespace = [mode, i].join('.')
        return createElement(RenderForm, {
          key: i,
          namespace,
          increase,
          remove,
          index,
          readOnly,
          n,
          i,
          canIncrease: useData.length < 6,
          mode,
        })
      }),
    [useData, mode, index, readOnly]
  )
}

export default ClaimForm
