import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { Formik, Form, useFormikContext } from 'formik';
import { navigate } from "gatsby"
import * as yup from 'yup'
import size from 'lodash/size'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import useFetch from 'use-http'
import isDate from 'date-fns/isDate'
// import styled from 'styled-components'
import flatten from 'flat'

import Box from 'components/Box'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Text from 'components/Text'
import ContentLayout from 'components/ContentLayout'

import ClaimProvider from 'contexts/apply/Provider'
import applyContext from 'contexts/apply/context'

import { responsive } from '../../components/ThemeProvider/theme';
import AllClaimData from './AllClaimData'
import messages from '../../utils/messages'
import idSchema from '../../utils/idSchema'
import foreignIdSchema from '../../utils/foreignIdSchema'
import twcityzip from './twcityzip';

import captchaShape from '../Captcha/shape'

import scrollToError from '../../utils/scrollToError'
import memorize from '../../utils/memorize'

// import demoInput from './demoInput.json'

const manFieldName = {
  name: '姓名',
  titid: '稱謂',
  phone1: '連絡電話',
  phone2: '手機',
  city: '縣市',
  zip: '區域',
  address: '地址',
  manidentity: '身分',
  national_number: '身分證號',
  company_number: '統一編號',
  email: 'E-mail',
  birth: '生日',
}

const getTrafficFieldName = (type) => mapValues({
  driverName: `${type}姓名`,
  driverSts: `${type}狀況`,
  passengerName: '乘客姓名',
  passengerSts: '乘客狀況',
  carOwner: '車主名稱',
  carNo: '車號',
  carType: '車種',
  carSts: '車損',
  pedestrianName: `${type}姓名`,
  pedestrianSts: `${type}狀況`,
}, v => `車禍類型-${type}-${v}`)

const fieldNames = {
  happendate: {
    y: '發生時間 - 年',
    minute: '發生時間 - 分',
    m: '發生時間 - 月',
    h: '發生時間 - 時',
    d: '發生時間 - 日',
  },
  happenevent: '案件說明',
  happenplace: {
    city: '發生地點 - 縣市',
    zip: '發生地點 - 區域',
    address: '發生地點 - 地址',
  },
  traffic: {
    apply: getTrafficFieldName('聲請人'),
    versus: getTrafficFieldName('對造人'),
  },
  event: {
    template: '案件說明 - 範本',
    content: '案件說明 - 發生經過',
  },
  upload: '上傳證件',
  carAccidentType: '車禍類型',
}

const getFieldName = memorize(key => {
  const applyMatch = /apply\.(\d)\.([^.]+)/.exec(key)
  if (applyMatch) {
    return `聲請人${applyMatch[1] * 1 + 1} - ${manFieldName[applyMatch[2]]}`
  }
  const versusMatch = /versus\.(\d)\.([^.]+)/.exec(key)
  if (versusMatch) {
    return `對造人${versusMatch[1] * 1 + 1} - ${manFieldName[versusMatch[2]]}`
  }
  const uploadMatch = /upload\.(\d)/.exec(key)
  if (uploadMatch) {
    return `上傳證件${uploadMatch[1] * 1 + 1}`
  }
  return get(fieldNames, key, key)
})

const FirstSubmitBtn = ({ onClick }) => {
  const [errorsToShow, setError] = useState()
  const {
    validateForm,
    setErrors,
    setTouched,
    // setFieldTouched,
    // values,
  } = useFormikContext()
  // console.log(values)
  const handleClick = async () => {
    // setFieldTouched('upload', true)
    const manualErrors = await validateForm()
    if (manualErrors.captcha) delete manualErrors.captcha
    if (size(manualErrors)) {
      setErrors(manualErrors)
      setTouched(manualErrors)
      setError(Object.entries(flatten(manualErrors)))
    } else {
      onClick()
    }
  }

  const onClose = () => {
    setError(false)
    scrollToError(errorsToShow)
  }
  return (
    <>
      <Button
        fontSize="1.25em"
        onClick={handleClick}
        type="button"
      >確認申請</Button>
      <Modal isOpen={errorsToShow} handleRequestClose={onClose}>
        <Box textAlign="center">
          <Text.H3 fontWeight="bold" mb="1em">請檢查以下欄位</Text.H3>
          <Box>
            {errorsToShow && errorsToShow.map(([k, v]) => v && (
              <Text key={k}><b>{getFieldName(k)}</b>：{v}</Text>
            ))}
          </Box>
          <Button.Purple my="1em" onClick={onClose}>知道了</Button.Purple>
        </Box>
      </Modal>
    </>
  )
}

const addrShape = {
  city: yup.string().required(),
  zip: yup.string().required().matches(/^\d+$/, messages.numberOnly),
  address: yup.string().max(100, '限100個字以內').required(),
}

const getPhoneValidate = (required, dep) => (required ? yup.string().when(dep, {
  is: v => !v,
  then: yup.string().required('電話擇一必填')
}) : yup.string()).max(20, '電話號碼最多20碼')

const personalDataShape = (required) => yup.object().shape({
  name: yup.string().max(200, '姓名最多200字').required(),
  titid: yup.number().required(),
  phone1: getPhoneValidate(required, 'phone2'),
  phone2: getPhoneValidate(required, 'phone1'),
  ...addrShape,
  birth: required ? yup.object().required().test('isDate', '日期不正確', value => {
    return !value || (
      [value.y, value.m, value.d].reduce((same, v, i, list) => !i || (same && Boolean(v) === Boolean(list[i - 1])), true)
      && isDate(new Date(value.y * 1 + 1911, value.m - 1, value.d))
    )
  }) : yup.object(),
  comtype: yup.string().oneOf(['y', 'n']).required(),
  manidentity: yup.string().oneOf(['1', '2', '3']).required(),
  company_number: yup.string().matches(/^\d+$/, messages.numberOnly).when('comtype', {
    is: 'y',
    then: required ? yup.string().length(8).required() : yup.string().length(8),
  }),
  national_number: yup.string().when('manidentity', {
    is: '3',
    then: idSchema,
    otherwise: yup.string().when('manidentity', {
      is: '2',
      then: required ? foreignIdSchema.required() : foreignIdSchema,
      otherwise: required ? idSchema.required() : idSchema,
    }),
  }),
  email: required ? yup.string().email().required() : yup.string().email(),
}, ['phone1', 'phone2'])

const driverShape = yup.object().shape({
  driverName: yup.string().max(200, '限200個字以內').required(),
  driverSts: yup.string().required(),
  carNo: yup.string().max(10, '限10個字以內'),
  carType: yup.string().max(20, '限20個字以內'),
})

const pedShape = yup.object().shape({
  pedestrianName: yup.string().max(200, '限200個字以內').required(),
  pedestrianSts: yup.string().required(),
})

const getTrafficEventShape = (index, v) => {
  switch (v) {
    case 1:
      return driverShape
    case 2:
      return index ? driverShape : pedShape
    case 3:
      return index ? pedShape : driverShape
    default:
      return yup.object()
  }
}

const getValidationSchema = type => {
  const isTraffic = type === 'traffic'
  return yup.object().shape({
    apply: yup.array().of(personalDataShape(true)),
    versus: yup.array().of(personalDataShape()),
    happendate: yup.object({
      y: yup.number().required(),
      m: yup.number().required(),
      d: yup.number().required(),
      h: yup.number().required(),
      minute: yup.number().required(),
    }).required(),
    happenevent: yup.mixed().required(),
    happenplace: yup.object().shape(addrShape).required(),
    orgselect: yup.number().default(1).required(),
    ...(isTraffic ? {
      traffic: yup.object()
        .when('carAccidentType',  {
          is: 1,
          then: yup.object({
            apply: getTrafficEventShape(0, 1),
            versus: getTrafficEventShape(1, 1),
          })
        })
        .when('carAccidentType',  {
          is: 2,
          then: yup.object({
            apply: getTrafficEventShape(0, 2),
            versus: getTrafficEventShape(1, 2),
          })
        })
        .when('carAccidentType',  {
          is: 3,
          then: yup.object({
            apply: getTrafficEventShape(0, 3),
            versus: getTrafficEventShape(1, 3),
          })
        }),
      carAccidentType: yup.number().required(),
    } : {
      event: yup.object().shape({
        template: yup.string().required(),
        memo: yup.string().max(500, '字數限制${max}字'),
        content: yup.string().max(800, '字數限制${max}字'),
      })
    }),
    upload: yup.array().test('hasId', '請上傳證件資料', value => (value && value[0] && value[1])),
    captcha: captchaShape,
  })
}

const padLeft = (v, len) => String(v).padStart(len, '0')

const formatValues = obj => mapValues(obj, (v, k) => {
  if (k === 'birth') return [padLeft(v.y, 3), padLeft(v.m, 2), padLeft(v.d, 2)].join('')
  if (k === 'happendate') {
    return [padLeft(v.y, 3), padLeft(v.m, 2), padLeft(v.d, 2)].join('')
  }
  if (k === 'happenplace' && isObject(v)) {
    return [twcityzip.zip[v.zip], v.address].join('')
  }
  // if (k === 'happenevent' && isObject(v)) return JSON.stringify(v)
  if (isArray(v)) return v.map(vv => isObject(vv) ? formatValues(vv) : vv)
  if (isObject(v)) return formatValues(v)
  return v
})

const Claim = ({ src, pageContext: { id }, setOpen, ...props }) => {
  const { applyByKey } = useContext(applyContext)
  const [isOpen, handleOpen] = useState()
  const [serverError, setServerError] = useState()
  const { post, response, error } = useFetch('/apply')
  const validationSchema = useMemo(() => getValidationSchema(id), [id])
  useEffect(() => {
    if (!id) {
      navigate('/apply')
    }
  }, [])
  const onSubmit = useCallback(async ({ daoid, ...values }, { setSubmitting, setFieldError, setErrors }) => {
    const vToSubmit = Object.assign({}, values)
    vToSubmit.happentime = [padLeft(vToSubmit.happendate.h, 2), padLeft(vToSubmit.happendate.minute, 2)].join('')
    vToSubmit.happenzip = vToSubmit.happenplace.zip
    if (daoid && daoid !== '_') vToSubmit.daoid = daoid
    const formmatedValues = formatValues(vToSubmit)
    const data = await post(formmatedValues)
    if (data.webapplyforno) {
      navigate(`/apply/${id}/thanks?no=${data.webapplyforno}`)
    } else {
      setFieldError('captcha.uuid', 'Refresh')
      if (response.status === 400) {
        if (data['captcha.input']) {
          setFieldError('captcha.uuid', 'Invalid')
        } else {
          handleOpen(false)
          scrollToError(data)
          setErrors(data)
          setTimeout(() => {
            setErrors(data)
          })
        }
      } else {
        setServerError(data || error)
      }
    }
    setSubmitting(false)
  }, [error, post, response, id])
  return (
    <ContentLayout
      title={id && applyByKey[id].text}
      {...props}
    >
      <Formik
        // initialValues={demoInput}
        initialValues={{
          apply: [{}],
          versus: [{}],
          traffic: {},
          carAccidentType: '1',
          captcha: {
            input: '',
          },
          event: {},
          orgselect: '1',
          kind: id === 'traffic' ? '2' : '1',
          upload: ['', ''],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <AllClaimData id={id} />
          <Box textAlign="center" mt="3em">
            <FirstSubmitBtn onClick={() => handleOpen(true)} />
          </Box>
          <Modal
            p={responsive('2.625em 0.875em', '5.75em 2em')}
            isOpen={isOpen}
            onRequestClose={() => {
              handleOpen(false)
              setServerError(null)
            }}
          >
            <AllClaimData isOpen={isOpen} id={id} handleOpen={handleOpen} serverError={serverError} />
          </Modal>
        </Form>
      </Formik>
    </ContentLayout>
  )
}

export default props => (
  <ClaimProvider>
    <Claim {...props} />
  </ClaimProvider>
)
