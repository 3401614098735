import flatten from 'flat'

export default errors => {
  setTimeout(() => {
    const idToScroll = Object.keys(flatten(errors)).find((id) => document.getElementById(id))
    if (idToScroll) {
      const pos = document.getElementById(idToScroll).getBoundingClientRect()
      window.scrollBy({ top: pos.top - 100, behavior: 'smooth'  })
    }
  })
}
