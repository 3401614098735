import { string } from 'yup'
import memorize from './memorize'

// https://oscar87xie.github.io/JS-validate-id-card-no-of-taiwan/

const alphabets = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
const digitMatches = {};
let i = alphabets.length;
while (i--) {
  digitMatches[alphabets.charAt(i)] = i + 10;
}
const weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

export default string()
  .length(10, '格式不正確')
  .uppercase()
  .matches(/^[A-Z]{1}[1-2]{1}[0-9]{8}$/, '格式不正確')
  .test('validateId', '字號驗證失敗', memorize(value => {
    if (!value) return true
    const firstChar = value.charAt(0);
    const matchDigit = digitMatches[firstChar];

    const identityNoDigits = value.replace(/[A-Z]/, matchDigit);
    const sum = weights.reduce((acc, weight, index) => {
      const num = parseInt(identityNoDigits.charAt(index), 10);
      const score = weight * num;
      return acc + score;
    }, 0);
    return sum % 10 === 0
  }))
