import { string } from 'yup'
// import memorize from './memorize'

// const pidCharArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
//   .reduce((all, char, i) => {
//     all[char] = i
//     return all
//   }, {})

// const pidResidentFirstInt = [1, 10, 9, 8, 7, 6, 5, 4, 9, 3, 2, 2, 11, 10, 8, 9, 8, 7, 6, 5, 4, 3, 11, 3, 12, 10]
// const pidResidentSecondInt = [0, 8, 6, 4, 2, 0, 8, 6, 2, 4, 2, 0, 8, 6, 0, 4, 2, 0, 8, 6, 4, 2, 6, 0, 8, 4]

export default string()
  .length(10, '長度須為10碼')
  // .matches(/^[A-Z][A-D]\d{8}$/, '格式不正確')
  // .test('validateId', '證號驗證失敗', memorize(value => {
  //   if (!value) return true
  //   let verifyNum = 0

  //   verifyNum += pidResidentFirstInt[pidCharArray[value[0]]];
  //   // 第二碼
  //   verifyNum += pidResidentSecondInt[pidCharArray[value[1]]];
  //   // 第三~八碼

  //   for (let i = 2, j = 7; i < 9; i++, j--) {
  //     verifyNum += value[i] * j;
  //   }
  //   // 檢查碼
  //   verifyNum = (10 - (verifyNum % 10)) % 10;

  //   return verifyNum == value[9]
  // }))
